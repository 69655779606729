<template>
    <v-container fluid fill-height>
        <v-layout align-center justify-center>
            <v-flex xs12 sm7 md5>
                <v-card class="elevation-6" style="max-width: 400px;margin: 0% auto">
                    <v-toolbar dense dark color="primary">
                        <v-toolbar-title>登录</v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text>
                        <v-form>
                            <v-text-field prepend-icon="person" name="login" label="邮箱" type="text" v-model="email">
                            </v-text-field>
                            <v-text-field id="password" prepend-icon="lock" name="password" label="密码" type="password"
                                :rules="[rules.required, rules.counter]" v-model="password" maxlength=12></v-text-field>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn color="primary" to='/auth/signup' disabled>注册？</v-btn>
                        <v-btn color="primary" to="/auth/password/reset" disabled>忘记密码</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" @click="EventSignIn">登录</v-btn>
                    </v-card-actions>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import { signin } from '@/api'
    import { event_emit } from '@/eventbus'

    export default {
        components: {},
        data() {
            return {
                email: '',
                password: '',
                rules: {
                    required: value => !!value || '必须',
                    counter: value => value.length <= 12 && value.length >= 6 || '6 ~ 12个字符',
                    email: value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        return pattern.test(value) || '请检查邮件地址'
                    }
                }
            }
        },
        props: [],
        methods: {
            EventSignIn() {
                if (!this.password || !this.email) {
                    event_emit(this, 'snackbar', { type: 'warning', msg: '检查用户邮箱或者密码' });
                    return
                }

                let postdata = { 'username': this.email, 'password': this.password }
                signin(postdata).then(res=>{
                    console.log(res)
                    window.localStorage.setItem("me", JSON.stringify(res.data));
                    this.$router.push({
                        path: "/"
                    });
                })
            },
        },
        mounted: function () { },
        beforeDestroy() {
            this.password = null
            this.email = null
            this.rules = null
        },
    }
</script>
<style>
    /* .maindialog {
  width: 420px;
  min-width:300px;
  margin:0 auto;
} */
</style>